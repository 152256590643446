import React from 'react'

import { DrugNutrient } from '../../../../components/drug-nutrient/DrugNutrient'
import { withDispensaryPageAdapter } from '../../../../hoc/withDispensaryPageAdapter'

const DrugNutrientPageRoute = () => {
  return <DrugNutrient />
}

export default withDispensaryPageAdapter(DrugNutrientPageRoute)
